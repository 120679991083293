import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import SearchModal from '../SearchModal';
import i18n from '../../i18n';

import Button from '../Button';

import styles from './NavigationMobile.module.scss';

const NavigationMobile = ({
    menu,
    contact,
    myPage,
    handleDisable,
    searchModal,
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const handleMenuToggle = useCallback(() => {
        handleDisable();
        setMenuIsOpen((menuIsOpen) => !menuIsOpen);
    }, [handleDisable]);

    const [expanded, setExpanded] = useState(false);
    const itemContainerRef = useRef(null);

    const toggleSearchModal = () => setExpanded(!expanded);
    const closeSearchModal = () => setExpanded(false);

    const menuClasses = classNames(styles['NavigationMobile'], {
        [styles['NavigationMobile--Open']]: menuIsOpen,
    });

    return (
        <nav
            className={menuClasses}
            role="navigation"
            aria-label={i18n.t('navigation.label')}
        >
            <div className={styles['NavigationMobile__Container']}>
                <div className={styles['NavigationMobile__Header']}>
                    <div className={styles['NavigationMobile__HeaderInner']}>
                        <div className={styles['NavigationMobile__Toggle']}>
                            <Button
                                modifier={'NavigationMobile'}
                                onClick={handleMenuToggle}
                                label={i18n.t('navigation.openMenuLabel')}
                                ariaExpanded={menuIsOpen}
                                ariaControls="modile-menu"
                            />
                        </div>
                        <a
                            href="/"
                            className={styles['NavigationMobile__Logotype']}
                        >
                            <img
                                src={'/assets/svg/logo-signalisten.svg'}
                                alt="Signalisten Logotyp"
                                className={styles['NavigationMobile__LogoIcon']}
                            />
                        </a>
                        <button
                            className={styles['NavigationMobile__Search']}
                            aria-label={i18n.t('navigation.openSearchLabel')}
                            onClick={toggleSearchModal}
                        />
                    </div>
                    {myPage.href && (
                        <div
                            className={styles['NavigationMobile__MyPageButton']}
                        >
                            <Button
                                modifier={'MyPageMobile'}
                                text={i18n.t('navigation.myPage')}
                                link={myPage.href}
                                type="Primary"
                                icon={'/assets/svg/my-account--white.svg'}
                            />
                        </div>
                    )}
                </div>
                <AnimateHeight duration={350} height={expanded ? 'auto' : 0}>
                    <div
                        id={i18n.t('NavigationSearch')}
                        className="Navigation__SearchModal"
                        role="region"
                        tabIndex="-1"
                        ref={itemContainerRef}
                    >
                        <SearchModal
                            closeSearchModal={closeSearchModal}
                            {...searchModal}
                        />
                    </div>
                </AnimateHeight>
                <div
                    id="modile-menu"
                    className={styles['NavigationMobile__Menu']}
                    aria-hidden={!menuIsOpen}
                >
                    <div className={styles['NavigationMobile__ItemsContainer']}>
                        <ul className={styles['NavigationMobile__Items']}>
                            {menu.map(({ href, title, items }, idx) => {
                                return (
                                    <li
                                        className={
                                            styles['NavigationMobile__Item']
                                        }
                                        key={idx}
                                    >
                                        <a href={href}>{title}</a>
                                        <SubNavigation
                                            currentTitle={title}
                                            items={items}
                                        />
                                    </li>
                                );
                            })}
                            {contact && (
                                <li
                                    className={styles['NavigationMobile__Item']}
                                >
                                    <a href={contact.href}>
                                        {i18n.t('navigation.contact')}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

const SubNavigation = ({ currentTitle, items, depth = 1 }) => {
    const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
    const handleSubMenuToggle = useCallback((e) => {
        e.preventDefault();
        setSubMenuIsOpen((subMenuIsOpen) => !subMenuIsOpen);
    }, []);

    const subMenuClasses = classNames(styles['NavigationMobile__SubMenu'], {
        [styles['NavigationMobile__SubMenu--Depth-1']]: depth === 1,
        [styles['NavigationMobile__SubMenu--Depth-2']]: depth === 2,
        [styles['NavigationMobile__SubMenu--Depth-3']]: depth === 3,
        [styles['NavigationMobile__SubMenu--Open']]: subMenuIsOpen,
    });

    const subButtonClasses = classNames(
        styles['NavigationMobile__ButtonToggle'],
        {
            [styles['NavigationMobile__ButtonToggle--Open']]: subMenuIsOpen,
        }
    );

    const hasSubMenuLinkItems = items && !!items.length;

    return (
        <>
            {hasSubMenuLinkItems && (
                <div className={subButtonClasses}>
                    <Button
                        onClick={handleSubMenuToggle}
                        ariaExpanded={subMenuIsOpen}
                        label={
                            subMenuIsOpen
                                ? i18n.t(
                                      'navigation.closeSubMenu',
                                      currentTitle
                                  )
                                : i18n.t('navigation.showSubMenu', currentTitle)
                        }
                        ariaControls="sub-menu"
                    />
                </div>
            )}

            {subMenuIsOpen && (
                <ul
                    id="sub-menu"
                    className={subMenuClasses}
                    aria-hidden={!subMenuIsOpen}
                >
                    {items.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <li>
                                    <a
                                        className={
                                            styles[
                                                'NavigationMobile__SubMenuItem'
                                            ]
                                        }
                                        href={item.href}
                                    >
                                        {item.title}
                                    </a>
                                    <SubNavigation
                                        currentTitle={item.title}
                                        items={item.items}
                                        depth={depth + 1}
                                    />
                                </li>
                            </React.Fragment>
                        );
                    })}
                </ul>
            )}
        </>
    );
};

SubNavigation.propTypes = {
    items: PropTypes.array,
    depth: PropTypes.number,
};

SubNavigation.defaultProps = {
    items: [],
    depth: 1,
};

NavigationMobile.propTypes = {
    menu: PropTypes.array,
    myPage: PropTypes.object,
    handleDisable: PropTypes.func,
    searchModal: PropTypes.object,
};

NavigationMobile.defaultProps = {
    menu: [],
    myPage: {},
    handleDisable: () => false,
    searchModal: {},
};

export default NavigationMobile;
