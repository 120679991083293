import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';
import isEmpty from '../../utils/isEmpty';
import i18n from '../../i18n';

const Footer = ({
    contact,
    address,
    info,
    social,
    copyrightYear,
    showIfCookieScriptisNotActivated,
}) => {
    return (
        <footer className={styles['Footer']}>
            <div className={styles['Footer__Image']}></div>
            <div className={styles['Footer__Menu']}>
                <div className={styles['Footer__MenuInner']}>
                    <div className={styles['Footer__MenuSection']}>
                        {!isEmpty(contact) && <ContactNav {...contact} />}
                        {!isEmpty(address) && <AddressNav {...address} />}
                    </div>
                    <div className={styles['Footer__MenuSection']}>
                        {!isEmpty(info) && (
                            <InfoNav
                                {...info}
                                showIfCookieScriptisNotActivated={
                                    showIfCookieScriptisNotActivated
                                }
                            />
                        )}
                        {!isEmpty(social) && <SocialNav {...social} />}
                    </div>
                </div>
                <div className={styles['Footer__Copyright']}>
                    {i18n.t('footer.copyright', copyrightYear)}
                </div>
            </div>
        </footer>
    );
};

const ContactNav = ({ title, text }) => {
    return (
        <nav
            role="navigation"
            className={styles['Footer__ContactNav']}
            aria-labelledby="footer-contact"
        >
            <h3 id="footer-contact">{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </nav>
    );
};

const AddressNav = ({ title, text }) => {
    return (
        <nav
            role="navigation"
            className={styles['Footer__AddressNav']}
            aria-labelledby="footer-address"
        >
            {!title && (
                <h3 className="sr-only" id="footer-address">
                    {i18n.t('footer.addressLabel')}
                </h3>
            )}
            {title && <h3 id="footer-address">{title}</h3>}
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </nav>
    );
};

const InfoNav = ({ title, items, showIfCookieScriptisNotActivated }) => {
    return (
        <nav
            role="navigation"
            className={styles['Footer__InfoNav']}
            aria-labelledby="footer-info"
        >
            <h3 id="footer-info">{title}</h3>
            {!isEmpty(items) &&
                items !== undefined &&
                items.map(({ href, title }, idx) => {
                    return (
                        <a key={idx} href={href}>
                            {title}
                        </a>
                    );
                })}
            {!showIfCookieScriptisNotActivated && (
                <a id="csconsentlink" href="#">
                    {i18n.t('footer.cookies')}
                </a>
            )}
        </nav>
    );
};

const SocialNav = ({ facebookUrl, linkedinUrl, instagramUrl }) => {
    return (
        <nav
            role="navigation"
            aria-labelledby="social-nav"
            className={styles['Footer__SocialNav']}
        >
            <h3 className="sr-only" id="social-nav">
                {i18n.t('footer.socialLabel')}
            </h3>
            {facebookUrl && (
                <a
                    href={facebookUrl}
                    className={
                        styles['Footer__SocialNav__Link'] +
                        ' ' +
                        styles['Footer__SocialNav__Link--Facebook']
                    }
                >
                    <span className="sr-only">Facebook</span>
                </a>
            )}
            {linkedinUrl && (
                <a
                    href={linkedinUrl}
                    className={
                        styles['Footer__SocialNav__Link'] +
                        ' ' +
                        styles['Footer__SocialNav__Link--Linkedin']
                    }
                >
                    <span className="sr-only">Linkedin</span>
                </a>
            )}
            {instagramUrl && (
                <a
                    href={instagramUrl}
                    className={
                        styles['Footer__SocialNav__Link'] +
                        ' ' +
                        styles['Footer__SocialNav__Link--Instagram']
                    }
                >
                    <span className="sr-only">Instagram</span>
                </a>
            )}
        </nav>
    );
};

Footer.defaultProps = {
    contact: {},
    address: {},
    info: {},
    social: {},
    copyrightYear: 0,
    showIfCookieScriptisNotActivated: false,
};

Footer.propTypes = {
    contact: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    address: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    info: PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.array,
    }),
    social: PropTypes.shape({
        facebookUrl: PropTypes.string,
        instagramUrl: PropTypes.string,
        youtubeUrl: PropTypes.string,
    }),
    showIfCookieScriptisNotActivated: PropTypes.bool,
    copyrightYear: PropTypes.number,
};

ContactNav.defaultProps = {
    title: '',
    text: '',
};
ContactNav.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

AddressNav.defaultProps = {
    title: '',
    text: '',
};

AddressNav.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

InfoNav.defaultProps = {
    title: '',
    items: [],
    showIfCookieScriptisNotActivated: false,
};

InfoNav.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    showIfCookieScriptisNotActivated: PropTypes.bool,
};

SocialNav.defaultProps = {
    facebookUrl: '',
    linkedinUrl: '',
    instagramUrl: '',
};

SocialNav.propTypes = {
    facebookUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
    instagramUrl: PropTypes.string,
};

export default Footer;
