const setCookie = (cname, cvalue) => {
    let d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const getCookie = (name) => {
    if (typeof window === 'undefined') {
        return '';
    }

    name = name + '=';
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let part = cookie[i];
        while (part.charAt(0) === ' ') {
            part = part.substring(1);
        }
        if (part.indexOf(name) === 0) {
            return part.substring(name.length, part.length);
        }
    }
    return '';
};

/**
 * Retrieves and returns lists of accepted and rejected categories based on the user's cookie consent.
 * This function reads the cookie consent from a cookie named 'CookieScriptConsent', parses it,
 * and extracts the categories based on the user's action ('accept' or 'reject').
 *
 * @returns {Object} An object containing two arrays: 'accept' and 'reject'. Each array contains categories
 *                   that have been either accepted or rejected by the user. If there's an error in parsing,
 *                   an object with empty arrays is returned.
 */
function getApprovedCookieTargets() {
    const targets = {
        accept: [],
        reject: [],
    };

    try {
        let consentData = getCookie('CookieScriptConsent');

        if (typeof consentData === 'string') {
            consentData = JSON.parse(consentData);
        }

        if (
            (consentData.action =
                'accept' && typeof consentData.categories === 'string')
        ) {
            JSON.parse(consentData.categories).forEach((category) => {
                targets.accept.push(category);
            });
        } else if (consentData.action === 'reject') {
            JSON.parse(consentData.categories).forEach((category) => {
                targets.reject.push(category);
            });
        }
    } catch (e) {
        console.error('Error parsing consent data:', e);
    }
    return targets;
}

export { setCookie, getCookie, getApprovedCookieTargets };
