import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Wysiwyg.module.scss';

const Wysiwyg = ({ focusable, html, modifier }) => {
    const classes = classNames(styles['Wysiwyg'], {
        [styles[`Wysiwyg--${modifier}`]]: !!modifier,
    });

    return (
        <div
            {...(focusable ? { tabIndex: 0 } : {})}
            className={classes}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

Wysiwyg.propTypes = {
    html: PropTypes.string,
    focusable: PropTypes.bool,
    modifier: PropTypes.string,
};

Wysiwyg.defaultProps = {
    html: '',
    modifier: '',
    focusable: false,
};

export default Wysiwyg;
