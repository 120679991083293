import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button';
import Wysiwyg from '../Wysiwyg';
import i18n from '../../i18n';

import styles from './Cookiebar.module.scss';
import { setCookie, getCookie } from '../../utils/cookie';

const Cookiebar = ({ content }) => {
    const [hidden, setHidden] = useState(true);
    const [hiding, setHiding] = useState(false);

    useEffect(() => {
        if (!hiding) {
            setHidden(getCookie('hide_cookiebar') === 'true' || false);
        }
    }, [hiding]);

    const handleAcceptAll = () => {
        setCookie('hide_cookiebar', true);
        setCookie('enableTracking', true);
        setHiding(true);
        setTimeout(() => {
            setHidden(true);
            window?.location?.reload();
        }, 1000);
    };

    const handleAcceptAllButTracking = () => {
        setCookie('hide_cookiebar', true);
        setHiding(true);
        setTimeout(() => {
            setHidden(true);
        }, 1000);
    };

    const classes = classNames(
        styles['Cookiebar'],
        { [styles['Cookiebar--Hidden']]: hidden },
        { [styles['Cookiebar--Hiding']]: hiding }
    );

    return (
        content && (
            <div className={classes}>
                <Wysiwyg
                    modifier={'CookieBar'}
                    className={styles['Cookiebar__Text']}
                    html={content}
                />
                <div className={styles['Cookiebar__Buttons']}>
                    <Button
                        text={i18n.t('cookiebar.acceptNecessary')}
                        onClick={handleAcceptAllButTracking}
                        modifier="PrimaryBorder"
                    />
                    <Button
                        text={i18n.t('cookiebar.acceptAll')}
                        onClick={handleAcceptAll}
                        modifier="PrimaryInverted"
                    />
                </div>
            </div>
        )
    );
};

Cookiebar.propTypes = {
    content: PropTypes.string,
};

Cookiebar.defaultProps = {
    content: '',
};

export default Cookiebar;
