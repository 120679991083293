import React from 'react';
import PropTypes from 'prop-types';
import styles from './BasePage.module.scss';
import { logoutFromPasswordProtectedPage } from '../../api/wagtail';
import i18n from '../../i18n';
import Head from 'next/head';
import classNames from 'classnames';
import useIsDesktop from '../../utils/useIsDesktop';
import Navigation from '../../components/Navigation';
import WagtailUserbar from '../../components/WagtailUserbar';
import NavigationMobile from '../../components/NavigationMobile';
import Footer from '../../components/Footer';
import Snackbar from '../../components/Snackbar';
import Cookiebar from '../../components/Cookiebar';

const BasePage = ({
    wagtailUserbar,
    seo,
    children,
    navigation,
    footer,
    viewRestrictions,
    currentPage,
    cookies,
    siteSetting,
}) => {
    const isDesktop = useIsDesktop();

    const handlePageLogoutClick = async () => {
        const viewRestriction = viewRestrictions[0];

        await logoutFromPasswordProtectedPage(
            viewRestriction.id,
            viewRestriction.pageId
        );

        // eslint-disable-next-line no-self-assign
        location = location;
    };

    const showIfCookieScriptisNotActivated = !siteSetting?.activateCookieScript;

    const {
        seoHtmlTitle,
        seoMetaDescription,
        seoOgTitle,
        seoOgDescription,
        seoOgUrl,
        seoOgImage,
        seoOgType,
        seoTwitterTitle,
        seoTwitterDescription,
        seoTwitterUrl,
        seoTwitterImage,
        seoMetaRobots,
        canonicalLink,
    } = seo;

    const baseClasses = classNames(
        styles['BasePage'],
        styles[`BasePage--${currentPage}`]
    );

    return (
        <>
            <Head>
                <title>{seoHtmlTitle}</title>
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com/"
                    crossOrigin={'true'}
                />
                <link rel="dns-prefetch" href="//fonts.googleapis.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@700&display=swap"
                    rel="stylesheet"
                />

                {!!seoMetaDescription && (
                    <meta name="description" content={seoMetaDescription} />
                )}
                {!!seoOgTitle && (
                    <meta property="og:title" content={seoOgTitle} />
                )}
                {!!seoOgDescription && (
                    <meta
                        property="og:description"
                        content={seoOgDescription}
                    />
                )}
                {!!seoOgUrl && <meta property="og:url" content={seoOgUrl} />}
                {!!seoOgImage && (
                    <meta property="og:image" content={seoOgImage} />
                )}
                {!!seoOgType && <meta property="og:type" content={seoOgType} />}
                {!!seoTwitterTitle && (
                    <meta property="twitter:title" content={seoTwitterTitle} />
                )}
                {!!seoTwitterDescription && (
                    <meta
                        property="twitter:description"
                        content={seoTwitterDescription}
                    />
                )}
                {!!seoTwitterUrl && (
                    <meta property="twitter:url" content={seoTwitterUrl} />
                )}
                {!!seoTwitterImage && (
                    <meta property="twitter:image" content={seoTwitterImage} />
                )}
                <meta name="robots" content={seoMetaRobots} />
                {!!canonicalLink && (
                    <link rel="canonical" href={canonicalLink} />
                )}
            </Head>
            <main className={baseClasses}>
                <a className={styles.SkipLink} href="#main-content">
                    Hoppa till huvudinnehåll
                </a>

                {showIfCookieScriptisNotActivated && <Cookiebar {...cookies} />}
                {isDesktop ? (
                    <Navigation {...navigation} />
                ) : (
                    <NavigationMobile {...navigation} />
                )}
                {viewRestrictions &&
                    viewRestrictions.length > 0 &&
                    viewRestrictions.filter(
                        (x) => x.restrictionType === 'password'
                    ).length > 0 && (
                        <Snackbar
                            text={i18n.t('basePage.loggedInOnPageTitle')}
                            buttonText={i18n.t('basePage.loggedInOnPageButton')}
                            onClick={handlePageLogoutClick}
                        />
                    )}
                <div id="main-content">{children}</div>
                <Footer
                    {...footer}
                    showIfCookieScriptisNotActivated={
                        showIfCookieScriptisNotActivated
                    }
                />
                {/*<KundoBlock />*/}
                {wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
            </main>
        </>
    );
};

BasePage.propTypes = {
    children: PropTypes.node,
    navigation: PropTypes.object,
    footer: PropTypes.object,
    cookies: PropTypes.object,
    wagtailUserbar: PropTypes.object,
    currentPage: PropTypes.string,
    seo: PropTypes.object,
    viewRestrictions: PropTypes.array,
    siteSetting: PropTypes.object,
};

BasePage.defaultProps = {
    children: {},
    navigation: {},
    footer: {},
    wagtailUserbar: {},
    cookies: {},
    currentPage: '',
    seo: {},
    viewRestrictions: [],
    siteSetting: {},
};

export default BasePage;
