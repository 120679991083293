import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';
import NavigationLocal from '../NavigationLocal';
import SearchModal from '../SearchModal';
import isEmpty from '../../utils/isEmpty';
import AnimateHeight from 'react-animate-height';
import i18n from '../../i18n';

import styles from './Navigation.module.scss';

const Navigation = ({
    menu,
    contact,
    search,
    myPage,
    localMenu,
    searchModal,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const [expanded, setExpanded] = useState(false);
    const itemContainerRef = useRef(null);

    const toggleSearchModal = () => setExpanded(!expanded);
    const closeSearchModal = () => setExpanded(false);

    const dropdownMenuClass = classNames(styles['Navigation__DropdownMenu'], {
        [styles['Navigation__DropdownMenu--Visible']]: isVisible,
    });

    return (
        <nav className={styles['Navigation']} role="navigation">
            <div className={styles['Navigation__Container']}>
                <div className={styles['Navigation__MenuWrap']}>
                    <div className={styles['NavigationMobile__Header']}>
                        <a href="/" className={styles['Navigation__Logotype']}>
                            <img
                                src={'/assets/svg/logo-signalisten.svg'}
                                alt="Signalisten Logotyp"
                                className="Hero__LogoIcon"
                            />
                        </a>
                    </div>
                    <div className={styles['Navigation__Menu']}>
                        <div
                            className={styles['Navigation__TopMenu']}
                            aria-expanded={isVisible}
                            onMouseLeave={() => setIsVisible(false)}
                            aria-controls="dropdown-menu"
                        >
                            <div className={styles['Navigation__TopMenuInner']}>
                                {menu.map(
                                    (
                                        { href, title, isActive, pageType },
                                        idx
                                    ) => {
                                        return (
                                            <NavItem
                                                key={idx}
                                                onMouseOver={() =>
                                                    setIsVisible(true)
                                                }
                                                href={href}
                                                title={title}
                                                isActive={isActive}
                                                pageType={pageType}
                                            />
                                        );
                                    }
                                )}
                            </div>

                            <div
                                id="dropdown-menu"
                                className={dropdownMenuClass}
                                aria-hidden={!isVisible}
                            >
                                <div
                                    className={
                                        styles['Navigation__DropdownMenuInner']
                                    }
                                >
                                    {menu.map(
                                        (
                                            { href, title, items, pageType },
                                            idx
                                        ) => {
                                            const menuWithItems = menu.filter(
                                                (x) => x.items.length > 0
                                            );
                                            return (
                                                <DropdownItem
                                                    key={idx}
                                                    navId={`nav-id-${idx}`}
                                                    href={href}
                                                    title={title}
                                                    items={items}
                                                    contact={contact}
                                                    isLast={
                                                        idx + 1 ===
                                                        menuWithItems.length
                                                    }
                                                    pageType={pageType}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles['Navigation__Search']}>
                            <Button
                                text={search.text}
                                onClick={toggleSearchModal}
                                aria-expanded={expanded}
                                modifier={'SearchButton'}
                                aria-controls={'NavigationSearch'}
                                label={
                                    expanded
                                        ? i18n.t('search.close')
                                        : i18n.t('search.open')
                                }
                                icon={'/assets/svg/search--black.svg'}
                            />
                        </div>
                        <div className={styles['Navigation__MyPage']}>
                            {myPage.href && (
                                <Button
                                    text={i18n.t('navigation.myPage')}
                                    link={myPage.href}
                                    type="Primary"
                                    modifier={'MyPages'}
                                    icon={'/assets/svg/my-account--white.svg'}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <NavigationLocal localMenu={localMenu} />
            <AnimateHeight duration={350} height={expanded ? 'auto' : 0}>
                <div
                    id={'NavigationSearch'}
                    className={styles['Navigation__SearchModal']}
                    role="region"
                    tabIndex="-1"
                    ref={itemContainerRef}
                >
                    <SearchModal
                        closeSearchModal={closeSearchModal}
                        isExpanded={expanded}
                        isClosable={true}
                        {...searchModal}
                    />
                </div>
            </AnimateHeight>
        </nav>
    );
};

const NavItem = ({ title, href, isActive, onMouseOver, onMouseLeave }) => {
    const navItemClasses = classNames(styles['Navigation__NavItem'], {
        [styles['Navigation__NavItem--Active']]: isActive,
    });

    return (
        <div className={navItemClasses}>
            <a
                className={styles['Navigation__NavLink']}
                href={href}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            >
                {title}
            </a>
        </div>
    );
};

const DropdownItem = ({ title, href, items, navId, contact, isLast }) => {
    return (
        !isEmpty(items) && (
            <div className={styles['Navigation__DropdownNavItem']}>
                <a
                    className={styles['Navigation__DropdownNavLink']}
                    href={href}
                >
                    {title}
                </a>
                <Dropdown navId={navId} items={items} />
                {isLast && contact && (
                    <Button
                        text={i18n.t('navigation.contact')}
                        modifier={'Contact'}
                        link={contact.href}
                        type="Secondary"
                    />
                )}
            </div>
        )
    );
};

const Dropdown = ({ items, navId }) => {
    return (
        <div id={navId} className={styles['Navigation__Dropdown']}>
            <div className={styles['Navigation__DropdownWrap']}>
                {items.map(({ title, href }, index) => (
                    <div
                        className={styles['Navigation__DropdownOuterList']}
                        key={index}
                    >
                        <div
                            className={styles['Navigation__DropdownInnerWrap']}
                        >
                            <ul>
                                <li key={index}>
                                    <a
                                        className={
                                            styles[
                                                'Navigation__DropdownInnerLink'
                                            ]
                                        }
                                        href={href}
                                    >
                                        {title}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

NavItem.propTypes = {
    idx: PropTypes.number,
    title: PropTypes.string,
    isActive: PropTypes.bool,
    href: PropTypes.string,
    pageType: PropTypes.string,
    items: PropTypes.array,
    navId: PropTypes.string,
};

DropdownItem.propTypes = {
    idx: PropTypes.number,
    title: PropTypes.string,
    href: PropTypes.string,
    items: PropTypes.array,
    navId: PropTypes.string,
    isLast: PropTypes.bool,
};

Dropdown.propTypes = {
    items: PropTypes.array,
    navId: PropTypes.string,
};

Navigation.propTypes = {
    menu: PropTypes.array,
    localMenu: PropTypes.array,
    contact: PropTypes.object,
    myPage: PropTypes.object,
    search: PropTypes.object,
    searchModal: PropTypes.object,
};

Navigation.defaultProps = {
    menu: [],
    localMenu: [],
    contact: {},
    myPage: {},
    search: {},
    searchModal: {},
};

export default Navigation;
