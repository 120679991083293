function isEmpty(variable) {
    const isArray = Array.isArray(variable);
    const isObject = typeof variable === 'object';
    const isString = typeof variable === 'string';
    const isBoolean = typeof variable === 'boolean';

    if (isBoolean) {
        return variable;
    }

    if (isArray && variable.length === 0) {
        return true;
    }

    if (!isArray && isObject && Object.keys(variable).length === 0) {
        return true;
    }

    if (isString && variable === '') {
        return true;
    }

    // this is probably a bool
    return false;
}

export default isEmpty;
