import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './NavigationLocal.module.scss';
import classNames from 'classnames';
import isEmpty from '../../utils/isEmpty';

const NavigationLocal = ({ localMenu }) => {
    return (
        !isEmpty(localMenu) && (
            <div className={styles['NavigationLocal']}>
                <div className={styles['NavigationLocal__Container']}>
                    <ul className={styles['NavigationLocal__Items']}>
                        {localMenu.map(
                            ({ href, title, id, isActive, items }, idx) => {
                                return (
                                    <NavItem
                                        key={idx}
                                        id={id}
                                        href={href}
                                        title={title}
                                        isActive={isActive}
                                        items={items}
                                    />
                                );
                            }
                        )}
                    </ul>
                </div>
            </div>
        )
    );
};

const NavItem = ({ title, href, isActive, id, items }) => {
    const [isVisible, setIsVisible] = useState(false);

    const subMenuClass = classNames(styles['NavigationLocal__SubItems'], {
        [styles['NavigationLocal__SubItems--Visible']]: isVisible,
    });

    const navItemClasses = classNames(styles['NavigationLocal__Item'], {
        [styles['NavigationLocal__Item--Active']]: isActive,
        [styles['NavigationLocal__Item--HasChildren']]: items.length > 0,
    });

    return (
        <li
            className={navItemClasses}
            onMouseOver={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            aria-expanded={isVisible}
            aria-controls={`local-sub-menu${id}`}
        >
            <a href={href}>{title}</a>
            {!isEmpty(items) && (
                <ul
                    id={`local-sub-menu${id}`}
                    className={subMenuClass}
                    aria-hidden={!isVisible}
                >
                    {items.map(({ href, title }, idx) => {
                        return (
                            <NavSubItem
                                key={idx}
                                idx={idx}
                                href={href}
                                title={title}
                            />
                        );
                    })}
                </ul>
            )}
        </li>
    );
};

const NavSubItem = ({ href, title, idx }) => {
    return (
        <li className={styles['NavigationLocal__SubItem']} key={idx}>
            <a href={href}>{title}</a>
        </li>
    );
};

NavigationLocal.propTypes = {
    locaalMenu: PropTypes.array,
};

NavItem.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    isActive: PropTypes.bool,
    idx: PropTypes.number,
    items: PropTypes.array,
};

NavSubItem.defaultProps = {
    title: PropTypes.string,
    href: PropTypes.string,
    idx: PropTypes.number,
};

NavigationLocal.defaultProps = {
    localMenu: [],
};

export default NavigationLocal;
