import BasePage from './BasePage';
import React from 'react';
import PropTypes from 'prop-types';

export const basePageWrap = (Component) => {
    const WrappedComponent = (props) => {
        return (
            <BasePage
                {...props}
                _class={Component.name}
                currentPage={Component.name}
            >
                <Component {...props} />
            </BasePage>
        );
    };
    WrappedComponent.displayName = `BasePageWrap(${
        Component.displayName || Component.name || 'Component'
    })`;

    return WrappedComponent;
};

export default BasePage;

basePageWrap.propTypes = {
    Component: PropTypes.func,
};

basePageWrap.defaultProps = {
    Component: () => {},
};
