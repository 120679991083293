/**
 * Usage:
 * import i18n from 'i18n'
 *
 * then call translate like this:
 * i18n.t('hello.world', 'Fallback')
 *
 * See: https://www.i18next.com/ for more information
 */

// import i18next from 'i18next';
import sv from './translations/sv.json';

// i18next.init({
//     resources: {
//         sv: {
//             translation: sv,
//         },
//     },
//     lng: 'sv',
//     interpolation: {
//         escapeValue: false,
//     },
// });

const i18n = {
    t: (str, repl) => {
        let label = str.split('.').reduce((r, k) => r?.[k], sv);
        if (repl) {
            label = label.replace('{{replace}}', repl);
        }
        return label;
    },
};

export default i18n;
